import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import Img from '../Img';

const Marquee = ({
  autoWidth,
  alternate,
  gap,
  HoverPause,
  Items,
  ItemsPerFrame,
  MarqueeSpeed,
}) => {
  const getTotalItems = (ipf) =>
    alternate ? Items.length : Items.length + ipf;
  const marqueeAnimation = autoWidth
    ? {
        '@keyframes marquee': {
          from: {
            transform: 'translate(0)',
          },
          to: {
            transform: 'translate(-50%)',
          },
        },
        animation: `marquee ${
          MarqueeSpeed && getTotalItems(0)
            ? `${(MarqueeSpeed / 2) * getTotalItems(0)}ms`
            : `15s`
        } linear infinite ${alternate ? `alternate` : ``}`,
      }
    : {
        '@keyframes marquee': ItemsPerFrame.map((ipf) => ({
          from: {
            ml: 0,
          },
          to: {
            ml: `calc(((100%/${ipf || 3})*(${getTotalItems(ipf)} - ${
              ipf || 3
            })*-1))`,
          },
        })),
        animation: ItemsPerFrame.map(
          (ipf) =>
            `marquee ${
              MarqueeSpeed && getTotalItems(ipf)
                ? `${(MarqueeSpeed / 2) * getTotalItems(ipf)}ms`
                : `15s`
            } linear infinite ${alternate ? `alternate` : ``}`
        ),
      };
  const AutoWidthMarqueeInnerStyled = !autoWidth
    ? {
        width: ItemsPerFrame.map(
          (ipf) => `calc( 100% / ${ipf || 3} * ${getTotalItems(ipf)})`
        ),
      }
    : {};
  const marqueeInnerStyled = {
    '&:hover': {
      animationPlayState: HoverPause ? 'paused' : '',
    },
    display: 'flex',
    flexShrink: 0,
    listStyle: 'none',
    mb: 0,
    ...AutoWidthMarqueeInnerStyled,
    ...marqueeAnimation,
  };
  const autoWidthItemStyled = autoWidth
    ? { display: 'flex', flex: 'none', width: 'auto' }
    : {};
  const setMarqueeItemStyled = (index) => {
    return {
      alignItems: 'center',
      display: ItemsPerFrame.map((ipf) =>
        index < getTotalItems(ipf) ? 'flex' : 'none'
      ),
      justifyContent: 'center',
      mb: 0,
      overflow: 'hidden',
      px: `calc(${gap}/2)`,
      width: ItemsPerFrame.map((ipf) =>
        index < getTotalItems(ipf) ? `calc(100% / ${ipf || 3})` : 0
      ),
      ...autoWidthItemStyled,
    };
  };

  const updateImageArray = () => {
    const updatedArray = alternate ? Items : [...Items, ...Items];
    return updatedArray.map((item, index) => (
      <Box key={`MarqueeItem_${index}`} sx={setMarqueeItemStyled(index)}>
        <Img
          alt=""
          as="img"
          src={item.img}
          sx={{ maxWidth: `${item.width}px` }}
        />
      </Box>
    ));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        my: 2,
        overflow: 'hidden',
      }}
    >
      <Box sx={marqueeInnerStyled}>{updateImageArray()}</Box>
    </Box>
  );
};

Marquee.propTypes = {
  alternate: PropTypes.bool,
  autoWidth: PropTypes.bool,
  gap: PropTypes.string || PropTypes.number,
  HoverPause: PropTypes.bool,
  Items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  ItemsPerFrame: PropTypes.arrayOf(PropTypes.number),
  MarqueeSpeed: PropTypes.string,
};

Marquee.defaultProps = {
  alternate: false,
  autoWidth: true,
  gap: '54px',
  HoverPause: true,
  ItemsPerFrame: [3, 3, 5, 8, 11],
  MarqueeSpeed: '3500',
};

export default Marquee;
