import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import Title from '../Title';
import SubTitle from '../SubTitle';
import HoverCards from '../HoverCards';

const CardGrid = ({ cards, stripeData }) => {
  return (
    <>
      <Box
        sx={{
          pb: 1,
          pt: 3,
          textAlign: 'center',
        }}
      >
        <Title
          sx={{
            color: 'grays.8',
            fontSize: 7,
            fontWeight: 'medium',
            letterSpacing: '-0.05em',
          }}
        >
          {stripeData.title}
        </Title>
        <SubTitle sx={{ color: 'grays.7', fontWeight: 'medium' }}>
          {stripeData.description}
        </SubTitle>
      </Box>
      <HoverCards cards={cards} />
    </>
  );
};

CardGrid.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stripeData: PropTypes.shape({
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default CardGrid;
