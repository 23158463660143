import React from 'react';
import Box from '../../base/Box';
import SEO from '../SEO';
import HeroCarousel from '../HeroCarousel';
import Stripe from '../Stripe';
import CardGrid from '../CardGrid';
import ExpMap from '../ExparienceMap';
import Marquee from '../Marquee';
import HomeContainer from '../../containers/HomeContainer';

const Home = () => {
  return (
    <HomeContainer>
      {(bannerData, experienceData, otherData, logoData) => {
        const bannerInfo = bannerData[0];
        const experienceInfo = experienceData[0];
        const otherInfo = otherData[0];
        const logoInfo = logoData[0];
        return (
          <Box>
            <SEO />
            {bannerInfo.heroBanners.length > 0 && (
              <HeroCarousel
                btnText={bannerInfo.btnText}
                slidesData={bannerInfo.heroBanners}
              />
            )}
            {logoInfo.clientsLogos.length > 0 && (
              <Marquee Items={logoInfo.clientsLogos} />
            )}
            <Stripe stripeData={otherInfo.blue_stripe} />
            {experienceInfo.experienceList.length > 0 && (
              <CardGrid
                cards={experienceInfo.experienceList}
                stripeData={otherInfo.white_stripe}
              />
            )}
            <ExpMap mapData={otherInfo.map_data} />
          </Box>
        );
      }}
    </HomeContainer>
  );
};
export default Home;
