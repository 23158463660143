import PropTypes from 'prop-types';
import React from 'react';
import Box from '../../base/Box';
import Carousel from '../Carousel';
import Banner from '../Banner';
import Button from '../Button';
import arrowCircleLight from '../../images/icons/arrow-right-circle-icon.svg';
import arrowCircleDark from '../../images/icons/arrow-right-circle-icon-dark.svg';
import Title from '../Title';
import Img from '../Img';
import Container from '../Container';

const HeroCarousel = ({ slidesData, settings, btnText }) => {
  const setBanner = (banner, key) => (
    <Banner
      key={key}
      bgImage={banner.bannerImage}
      imageSx={{
        opacity: 0.5,
      }}
      sx={{
        justifyContent: ['center', null, 'flex-end', null, null],
        minHeight: [null, null, null, '477px', null],
      }}
    >
      <Container
        sx={{
          alignItems: 'flex-end',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          margin: '0 auto',
          maxWidth: [
            'calc(100% - 7rem)',
            null,
            'calc(100% - 7rem)',
            null,
            'maxWidths.content',
          ],
          position: 'relative',
          py: [5, null, 2, null, null],
          width: '100%',
        }}
      >
        <Box
          sx={{
            '.slick-slide & > *': {
              animation: `fade-out 0.5s 0s cubic-bezier(0.390, 0.575, 0.565, 1.000) both`,
            },
            '.slick-slide.slick-active & > *:nth-child(1)': {
              animation: `fade-in-bottom 0.5s .5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both`,
            },
            '.slick-slide.slick-active & > *:nth-child(2)': {
              animation: `fade-in-bottom 0.5s .65s cubic-bezier(0.390, 0.575, 0.565, 1.000) both`,
            },
            '.slick-slide.slick-active & > *:nth-child(3)': {
              animation: `fade-in-bottom 0.5s .75s cubic-bezier(0.390, 0.575, 0.565, 1.000) both`,
            },
            '@keyframes fade-in-bottom': {
              '0%': {
                opacity: 0,
                transform: `translateY(50px) scale(0.9)`,
              },
              '100%': {
                opacity: 1,
                transform: 'translateY(0) scale(1)',
              },
            },
            '@keyframes fade-out': {
              '0%': {
                opacity: 1,
              },
              '100%': {
                opacity: 0,
              },
            },
            alignItems: ['center', null, null, 'flex-end', null],
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Title
            sx={{
              color: 'white',
              fontFamily: 'primary',
              fontSize: [8, null, 10, null, null],
              fontWeight: 'medium',
              letterSpacing: '0.03em',
              mb: 4,
              textAlign: ['center', null, null, 'right', null],
              textShadow: '0px 2px 1px #000000',
              textTransform: 'uppercase',
            }}
          >
            {banner.content.split('\n').map((text, index) => {
              return (
                <p key={index}>
                  {text}
                  <br />
                </p>
              );
            })}
          </Title>
          {banner.redirection && (
            <Box>
              <Button
                hoverSx={{
                  '> img:last-child': {
                    display: 'inline-block',
                  },
                  '> img:nth-last-child(2)': {
                    display: 'none',
                  },
                  bg: 'grays.11',
                  color: 'grays.9',
                }}
                link={banner.redirection}
                sx={{
                  '> img:last-child': {
                    display: 'none',
                  },
                  '> img:nth-last-child(2)': {
                    display: 'inline-block',
                  },
                  bg: 'warning',
                  color: 'white',
                  fontSize: 2,
                }}
              >
                <span>{btnText}</span>
                <Img alt="" as="img" src={arrowCircleLight} sx={{ ml: 2 }} />
                <Img alt="" as="img" src={arrowCircleDark} sx={{ ml: 2 }} />
              </Button>
            </Box>
          )}
          <Box
            as="span"
            sx={{
              color: 'white',
              fontFamily: 'tertiary',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 300,
              lineHeight: '12px',
              minHeight: '22px',
              mt: 3,
              opacity: 0.7,
              textAlign: ['center', null, null, 'right', null],
            }}
          >
            {banner.attribute.split('\n').map((text, index) => {
              return (
                <p key={index}>
                  {text}
                  <br />
                </p>
              );
            }) || ''}
          </Box>
        </Box>
      </Container>
    </Banner>
  );
  return (
    <Carousel
      settings={settings}
      slides={slidesData.map((item, index) => setBanner(item, index))}
    />
  );
};

HeroCarousel.propTypes = {
  btnText: PropTypes.string.isRequired,
  settings: PropTypes.shape({}),
  slidesData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

HeroCarousel.defaultProps = {
  settings: {},
};

export default HeroCarousel;
