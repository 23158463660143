import PropTypes from 'prop-types';
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const HomeContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allHomePageBannersJson {
          edges {
            node {
              heroBanners {
                attribute
                bannerImage
                content
                redirection
              }
              btnText
            }
          }
        }
        allHomePageExperienceCardsJson {
          edges {
            node {
              experienceList {
                details
                image
                link
                subTitle
                title
                linkTitle
              }
            }
          }
        }
        allHomePageLogosJson {
          edges {
            node {
              clientsLogos {
                img
                width
              }
            }
          }
        }
        allHomePageOtherDataJson {
          edges {
            node {
              blue_stripe {
                btnTitle
                certification
                description
              }
              map_data {
                left_text
                middle_text
                right_text
                title
              }
              white_stripe {
                description
                title
              }
            }
          }
        }
      }
    `}
    render={({
      allHomePageBannersJson: { edges: bannerData },
      allHomePageExperienceCardsJson: { edges: experienceData },
      allHomePageOtherDataJson: { edges: otherData },
      allHomePageLogosJson: { edges: logoData },
    }) =>
      children(
        bannerData.map((e) => ({
          ...e.node,
        })),
        experienceData.map((e) => ({
          ...e.node,
        })),
        otherData.map((e) => ({
          ...e.node,
        })),
        logoData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

HomeContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default HomeContainer;
