import React from 'react';
import PropTypes from 'prop-types';
import Banner from '../Banner';
import Title from '../Title';
import mapImage from '../../images/map.jpg';
import Box from '../../base/Box';
import SubTitle from '../SubTitle';

const ExpMap = ({ sx, mapData }) => {
  const pins = [
    { left: '8.78%', top: '41%' },
    { left: '28.1%', top: '67.2%' },
    { left: '50.27%', top: '21.4%' },
    { left: '55.77%', top: '92.28%' },
    { left: '64.7%', top: '51.5%' },
    { left: '68.6%', top: '42.2%' },
    { left: '70.3%', top: '55.2%' },
    { left: '88%', top: '25.1%' },
  ];
  const texts = [
    {
      label: `${mapData.left_text}`,
      left: '17%',
      top: '76%',
    },
    { label: `${mapData.right_text}`, left: '77%', top: '33%' },
  ];
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          my: 2,
          position: [null, null, null, 'absolute', null],
          textAlign: 'center',
          width: '100%',
          zIndex: 1,
        }}
      >
        <Title
          sx={{
            color: 'grays.8',
            fontSize: 7,
            fontWeight: 'medium',
            letterSpacing: '-0.05em',
          }}
        >
          {mapData.title}
        </Title>
        <SubTitle
          sx={{
            color: 'grays.7',
            fontFamily: 'tertiary',
            fontSize: 4,
            fontWeight: 'regular',
          }}
        >
          {mapData.middle_text}
        </SubTitle>
      </Box>
      <Banner
        bgImage={mapImage}
        imageSx={{
          opacity: '1',
        }}
        sx={sx}
      >
        <Box
          sx={{
            '&:before': {
              content: '""',
              display: 'block',
              paddingTop: 'calc((467/1440)*100%)',
              pointerEvents: 'none',
              width: '100%',
            },
            position: 'relative',
          }}
        >
          {texts.map((text, index) => (
            <Box
              key={`map-text-${index}`}
              as="span"
              sx={{
                // '@keyframes pulsate-bck': {
                //   '0%': {
                //     transform: 'scale(1)',
                //   },
                //   '50%': {
                //     transform: 'scale(0.9)',
                //   },
                //   '100%': {
                //     transform: 'scale(1)',
                //   },
                // },
                // animation: `pulsate-bck 4s ease-in-out infinite both`,
                color: 'grays.7',
                display: ['none', null, 'unset', null, null],
                fontFamily: 'tertiary',
                fontSize: 2,
                fontWeight: 'regular',
                left: text.left,
                lineHeight: '16px',
                position: 'absolute',
                top: text.top,
                transform: 'translate(-50%, -50%)',
                width: ['20%', null, null, 'auto', null],
              }}
            >
              {text.label}
            </Box>
          ))}
          {pins.map((pin, index) => (
            <Box
              key={`map-pin-${index}`}
              sx={{
                height: ['9px', null, '12px', null, null],
                left: pin.left,
                position: 'absolute',
                top: pin.top,
                transform: 'translate(-50%, -50%)',
                width: ['9px', null, '12px', null, null],
              }}
            >
              <Box
                sx={{
                  '&:before': {
                    animation: `ping 1.2s 0s ease-in-out infinite both`,
                    bg: 'inherit',
                    borderRadius: 'inherit',
                    content: '""',
                    display: 'block',
                    height: '100%',
                    width: '100%',
                  },
                  '@keyframes ping': {
                    '0%': {
                      opacity: '0.8',
                      transform: 'scale(1)',
                    },
                    '80%, 100%': {
                      opacity: '0',
                      transform: 'scale(4.2)',
                    },
                  },
                  bg: 'primary',
                  borderRadius: '100%',
                  height: 'calc(100% - 4px)',
                  m: '2px',
                  width: 'calc(100% - 4px)',
                }}
              />
            </Box>
          ))}
        </Box>
      </Banner>
    </Box>
  );
};

ExpMap.propTypes = {
  mapData: PropTypes.shape({
    left_text: PropTypes.string.isRequired,
    middle_text: PropTypes.string.isRequired,
    right_text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  sx: PropTypes.shape({}),
};

ExpMap.defaultProps = {
  sx: {},
};

export default ExpMap;
