import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../base/Box';
import Button from '../Button';
import arrowCircleRight from '../../images/icons/arrow-right-circle-icon.svg';
import arrowCircleRightWarning from '../../images/icons/arrow-right-circle-icon-warning.svg';
import Img from '../Img';
import Container from '../Container';

const Stripe = ({ stripeData }) => (
  <Box
    sx={{
      bg: 'brandBlue',
      color: 'white',
    }}
  >
    <Container>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: ['column', null, null, 'row', null],
          fontFamily: 'tertiary',
          fontSize: 3,
          fontWeight: 'medium',
          mr: [null, null, null, -3, null],
        }}
      >
        <Box
          sx={{
            flex: 'auto',
            py: [4, null, null, 0, null],
            textAlign: ['center', null, null, 'left', null],
          }}
        >
          {stripeData.description}
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flex: 'none',
            flexDirection: ['column', null, null, 'row', null],
            fontFamily: 'primary',
            fontSize: 2,
            fontWeight: 'bold',
            py: [4, null, null, 0, null],
          }}
        >
          <span>{stripeData.certification}</span>
          <Button
            hoverSx={{
              '> img:last-child': {
                display: 'inline-block',
              },
              '> img:nth-last-child(2)': {
                display: 'none',
              },
              bg: 'transparent',
              boxShadow: 'none',
              color: 'warning',
            }}
            link="/about-us/mbe"
            sx={{
              '> img:last-child': {
                display: 'none',
              },
              '> img:nth-last-child(2)': {
                display: 'inline-block',
              },
              bg: 'transparent',
              color: 'white',
              fontFamily: 'primary',
              fontSize: 1,
              fontWeight: 'semiBold',
              ml: [null, null, null, 4, null],
              mt: [4, null, null, 0, null],
            }}
          >
            <span>{stripeData.btnTitle}</span>
            <Img alt="" as="img" src={arrowCircleRight} sx={{ ml: 2 }} />
            <Img alt="" as="img" src={arrowCircleRightWarning} sx={{ ml: 2 }} />
          </Button>
        </Box>
      </Box>
    </Container>
  </Box>
);

Stripe.propTypes = {
  stripeData: PropTypes.shape({
    btnTitle: PropTypes.string.isRequired,
    certification: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default Stripe;
